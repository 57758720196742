/* eslint-disable no-undef */
var app = angular.module('PouchCue')
app.controller('RedirectController', [
  'GlobalService',
  function(GlobalService) {
    var ctrl = this

    ctrl.url = ''

    ctrl.init = function(user) {
      GlobalService.setUser(user)
    }
  }
])
